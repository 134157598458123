<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountInfor.bindEmailTitle2')}}</p>
          </div>
          <!-- 绑定电子邮箱 -->
          <div v-if="this.findUserInfo.emailIsVer == 0" class="identity-box">
            <p class="tips-p">{{$t('accountInfor.bindEmailTitle1')}}</p>
            <div class="from-box">
              <email-input @emailBlur="inputBlur" @inputVal="inputEmailVal" :errEmailList="errEmailList"/>
              <code-input :verCodeData="verCodeData" :errCodeList="errCodeList" @codeBlur="emailCodeBlur()" @getVerCodeEmit="getCode(1)"  @onPhoneCode="codeVal"/>
                <input @click="bindMailbox" class="input-btn" type="button" :value="$t('accountInfor.bind')" />
            </div>
          </div>

          <!-- 修改电子邮箱 -->
          <div v-if="this.findUserInfo.emailIsVer == 1" class="identity-box mailbox">
            <p class="tips-p">{{$t('accountInfor.bindEmailTitle5')}}</p>
            <div class="from-box">
              <email-input @emailBlur="inputBlur" @inputVal="inputEmailVal" :errEmailList="errEmailList" :emailValue="this.findUserInfo.email"/>
              <code-input :verCodeData="verCodeData" :errCodeList="errCodeList" @codeBlur="emailCodeBlur()" @getVerCodeEmit="getCode(2)"  @onPhoneCode="codeVal"/>
                <input @click="modifyMailbox" class="input-btn" type="button" :value="$t('perfectData.modify')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import EmailInput from '@/components/EmailInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import Dialog from '@/components/Dialog.vue';
import { getCookie } from '@/util/cookie';
import account from '@/api/account.js';
import {HEADER_USER_TOKEN, REG_EMAIL} from '@/util/const.js'
export default {
  name: "Mailbox",
  components: {
    Header,
    LeftMenu,
    AccountNav,
    EmailInput,
    CodeInput,
    Dialog
  },
  data() {
    return {
      domainUrl:'',
      loginState:2,
      isShowConfirm:false,
      findUserInfo:[],
      verCodeData: {
        isVerCode: '0',
        showTime:60,
        interval:'',
      },
      email:'',
      emailCode:'',
      errEmailList: {
        errShow:false,
        errContent:'',
      },
      errCodeList: {
        errShow:false,
        errContent:'',
      },
    }
  },
  created() {
    this.domainUrl = window.location.host;
    // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  methods: {
    // 获取输入的邮箱
    inputEmailVal(val){
      this.email = val
      console.log(this.email);
    },

    // 获取输入的验证码
    codeVal(val){
      this.emailCode = val
      console.log(this.emailCode);
    },

    // 鼠标离开输入框校验不能为空
    inputBlur(val){
      if(val == "email"){
        if(!this.email){
          this.errEmailList.errShow = true;
          this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt26'); 
        }else{
          if(!REG_EMAIL.test(this.email)){
            this.errEmailList.errShow = true;
            this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
          }else{
            this.errEmailList.errShow = false;
          }
        }        
      } 
    },
    emailCodeBlur(){
      if(!this.emailCode){
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
      }else{
        this.errCodeList.errShow = false;
      }
    },
    // 获取邮箱验证码
    getCode(num){
      if(!this.email){
        this.errEmailList.errShow = true;
        if(num == 1){
            this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt8');
          }else{
            this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt26');
          }           
        return; 
      }
      if(!REG_EMAIL.test(this.email)){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
        return; 
      }else{
        this.errEmailList.errShow = false;
      }
      let data = {
      countryId: this.findUserInfo.countryId,
      mail: this.email,
      domainUrl:this.domainUrl,
      }
      account.emailCode(data).then((res)=>{
        if(res.data.success == 1){
          let _this = this;
          _this.verCodeData.isVerCode = 1;
          _this.verCodeData.interval = setInterval(function(){
            _this.verCodeData.showTime--;
            if(_this.verCodeData.showTime < 1){
              clearInterval(_this.verCodeData.interval);
              _this.verCodeData.interval ="";
              _this.verCodeData.showTime = 60;
              _this.verCodeData.isVerCode = 0;
            }
          },1000);
        }else{
          this.errEmailList.errShow = true;
          this.errEmailList.errContent = res.data.message;
        }
        
      }).catch(err=>{
        console.log(err.message);
      })
      this.errEmailList.errShow = false;
         
    },

    // 点击修改邮箱
    modifyMailbox(){
      if(!this.email){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt26'); 
        return; 
      }
      if(!REG_EMAIL.test(this.email)){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
        return; 
      }else{
        this.errEmailList.errShow = false;
      }
      if(!this.emailCode){
        this.errEmailList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errCodeList.errShow = false;
      }

      let data = {
        email: this.email,
        verCode: this.emailCode,
        domainUrl:this.domainUrl,
      }
      account.updateEmail(data).then((res)=>{
        if(res.data.success == 1){
          const popTitle = this.$i18n.t('accountInfor.bindEmailTitle4');//多语言传入的文字
          const cancel = this.$i18n.t('perfectData.cancel');
          const confirm = this.$i18n.t('perfectData.continue');
          const title = this.$i18n.t('accountCenter.reminder');
          this.isShowConfirm = true
          this.$refs.myConfirm.show(popTitle, {
              type: 'confirm',
              cancelText:cancel,
              confirmText: confirm,
              titleText: title,
              data: '我是外界传进来的数据'
          })
        }else{
          this.errCodeList.errShow = true;
          this.errCodeList.errContent = res.data.message;
        }
      }).catch(err=>{
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = err.message;
        console.log(err.message);
      })
      
    },

    // 点击绑定邮箱
    bindMailbox(){
      if(!this.email){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt8'); 
        return; 
      }
      if(!REG_EMAIL.test(this.email)){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
        return; 
      }else{
        this.errEmailList.errShow = false;
      }
      if(!this.emailCode){
        this.errEmailList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errCodeList.errShow = false;
      }

      let data = {
        email: this.email,
        verCode: this.emailCode,
        domainUrl:this.domainUrl,
        
      }
      account.updateEmail(data).then((res)=>{
        if(res.data.success == 1){
          const popTitle = this.$i18n.t('accountInfor.bindEmailTitle3');//多语言传入的文字
          const cancel = this.$i18n.t('perfectData.cancel');
          const confirm = this.$i18n.t('perfectData.continue');
          const title = this.$i18n.t('accountCenter.reminder');
          this.isShowConfirm = true
          this.$refs.myConfirm.show(popTitle, {
              type: 'confirm',
              cancelText:cancel,
              confirmText: confirm,
              titleText: title,
              data: '我是外界传进来的数据'
          })
        }else{
          this.errCodeList.errShow = true;
          this.errCodeList.errContent = res.data.message;
        }
      }).catch(err=>{
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = err.message;
        console.log(err.message);
      })
    },

    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    }, 
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
